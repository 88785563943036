import React, { useState } from "react";
import "./menu.css";
import Modal from "react-modal";
import { useContext } from "react";
import AppContext from "../../appContext";
import Draggable from "react-draggable";

function ModalEchelle() {
  const { mesure, setMesure, setEchelle, echelle, scale, setScale } =
    useContext(AppContext);
  return (
    <div className="flex flex-col border rounded-lg modal-echelle">
      <Modal
        isOpen={echelle}
        onRequestClose={() => setEchelle(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",

            transform: "translate(-50%, -50%)",
            cursor: "default",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div className="px-4 pb-4 ">
          <div className="flex flex-row justify-center mb-8">
            <div
              className="text-2xl font-bold text-center"
              style={{ userSelect: "none" }}
            >
              Specification Echelle
            </div>
          </div>

          <div
            className={`flex flex-row mb-4 justify-items-center justify-center `}
          >
            <input
              type="number"
              value={mesure}
              onChange={(ev) => {
                ev.preventDefault();
                setMesure(ev.target.value);
                if (ev.target.value)
                  setScale({ ...scale, mm: ev.target.value });
              }}
              className="input-echelle"
              style={{
                borderStyle: "solid",
                borderColor: "#232323",
                borderRadius: "5px",
                paddingLeft: "5px",
              }}
            />
          </div>

          <div className="flex flex-row justify-items-center justify-center">
            <div
              onClick={() => setEchelle(false)}
              className="text-center border  bg-green-500 py-1 px-4 rounded-full  mx-auto outline-none focus:outline-none cursor-pointer main-background "
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalEchelle;
