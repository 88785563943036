import React, { Component, useContext, useState } from "react";
import "./menu.css";
import logo from "../../assets/logo.png";
import min from "../../assets/min.png";
import max from "../../assets/max.png";
import cross from "../../assets/cross.png";
import AppContext from "../../appContext";
import FileSaver from "file-saver";
import icon from "../../assets/icon/icon.ico";
import $ from "../../../node_modules/jquery/dist/jquery.min.js";

const Menu = ({ handleChange }) => {
  const [isFichier, setIsFichier] = useState(false);
  const [isFenetres, setIsFenetres] = useState(false);
  const [fichier, setFichier] = useState(null);
  const appCtx = useContext(AppContext);

  const setToolbar = () => {
    $("#toolbar").css({
      display: "block",
    });
  };

  const setStepBar = () => {
    $("#stepbar").css({
      display: "block",
    });
  };

  const toggleFullScreen = (elem) => {
    // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if (
      (document.fullScreenElement !== undefined &&
        document.fullScreenElement === null) ||
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement === null) ||
      (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
      (document.webkitIsFullScreen !== undefined &&
        !document.webkitIsFullScreen)
    ) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  async function fetchImageBlob() {
    const response = await fetch(icon);
    const blob = await response.blob();
    return blob;
  }
  const saveFile = async () => {
    const canvasDataUrl = appCtx.canvasImage.current.toDataURL();
    console.log("saving file ...");
    const imageData = {
      name: "myImage",
      type: "png",
      data: canvasDataUrl,
    };
    const file = {
      image: imageData,
      points: appCtx.arrayPoints,
      lines: appCtx.arrayLines,
      scale: appCtx.scale,
    };

    const json = JSON.stringify(file);
    const blob = new Blob([json], { type: "application/json" });

    try {
      const handle = await window.showSaveFilePicker({
        suggestedName: "data.cleft",
        types: [
          {
            description: "Fichier cleft",
            accept: {
              "application/cleft": [".cleft"],
            },
            icon: fetchImageBlob(),
          },
        ],
      });
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du fichier:", error);
    }
  };

  const loadFile = async () => {
    const [fileHandle] = await window.showOpenFilePicker({
      types: [
        {
          description: "Fichier cleft",
          accept: {
            "application/cleft": [".cleft"],
          },
        },
      ],
    });

    // Get a file object for the selected file
    const file = await fileHandle.getFile();

    // Create a new FileReader object
    const reader = new FileReader();

    // When the file has finished loading, parse the JSON object and draw the image on the canvas
    reader.onload = () => {
      const imageData = JSON.parse(reader.result);

      const image = new Image();
      image.src = imageData.image.data;
      console.log(imageData.image);

      image.onload = () => {
        appCtx.ctx.clearRect(0, 0, 1000, 1000);
        appCtx.ctx2.clearRect(0, 0, 1000, 1000);
        appCtx.ctxImage.clearRect(0, 0, 1000, 1000);
        appCtx.ctx3.clearRect(0, 0, 1000, 1000);
        appCtx.setArrayPoints([]);
        appCtx.setArrayLines([]);
        const h = appCtx.canvasParent.current.clientHeight;
        const w = appCtx.canvasParent.current.clientWidth;
        appCtx.canvasImage.current.width = w;

        appCtx.canvasImage.current.height = h;

        appCtx.canvas.current.width = w;
        appCtx.canvas.current.height = h;
        appCtx.canvas2.current.width = w;
        appCtx.canvas2.current.height = h;
        appCtx.canvas3.current.width = w;
        appCtx.canvas3.current.height = h;
        appCtx.parent.current.width = w;
        appCtx.parent.current.height = h;
        let im_w = image.width;
        let im_h = image.height;
        // Set the maximum width and height
        const maxWidth = 800;
        const maxHeight = 500;
        let newWidth = im_w;
        let newHeight = im_h;
        // Calculate the aspect ratio
        /*const aspectRatio = image.width / image.height;
        if (im_w > maxWidth) {
          // Calculate the new width and height while maintaining the aspect ratio
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
          }
        }
        if (im_h > maxHeight && newHeight > maxHeight) {
          // Calculate the new width and height while maintaining the aspect ratio
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;

          if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
          }
        }*/

        console.log("h,w", h, w, image.width, image.height);
        appCtx.ctxImage.drawImage(
          image,
          (w - newWidth) / 2,
          (h - newHeight) / 2,
          newWidth,
          newHeight
        );

        const points = imageData.points;
        const lines = imageData.lines;
        const scale = imageData.scale;
        appCtx.setScale(scale);
        appCtx.setArrayPoints([...points]);
        appCtx.setArrayLines([...lines]);
        lines.forEach((line) => {
          appCtx.drawLine3({
            p1: line.p1,
            p2: line.p2,
            style: { color: line.color },
          });
        });
        points.forEach((point) => {
          appCtx.drawCircle({
            x: point.x,
            y: point.y,
            style: { color: point.color },
          });
        });
      };
    };

    reader.readAsText(file);
  };

  const loadImage = async () => {
    const [fileHandle] = await window.showOpenFilePicker({
      types: [
        {
          description: "Image Files",
          accept: {
            "image/*": [".png", ".jpeg", ".jpg", ".bmp"],
          },
        },
      ],
    });

    const file = await fileHandle.getFile();
    const reader = new FileReader();

    // When the file has finished loading, parse the JSON object and draw the image on the canvas
    reader.onload = (readerEvent) => {
      const image = new Image();

      image.onload = () => {
        appCtx.ctx.clearRect(0, 0, 1000, 1000);
        appCtx.ctx2.clearRect(0, 0, 1000, 1000);
        appCtx.ctxImage.clearRect(0, 0, 1000, 1000);
        appCtx.ctx3.clearRect(0, 0, 1000, 1000);
        appCtx.setArrayPoints([]);
        appCtx.setArrayLines([]);
        const h = appCtx.canvasParent.current.clientHeight;
        const w = appCtx.canvasParent.current.clientWidth;
        appCtx.canvasImage.current.width = w;

        appCtx.canvasImage.current.height = h;

        appCtx.canvas.current.width = w;
        appCtx.canvas.current.height = h;
        appCtx.canvas2.current.width = w;
        appCtx.canvas2.current.height = h;
        appCtx.canvas3.current.width = w;
        appCtx.canvas3.current.height = h;
        appCtx.parent.current.width = w;
        appCtx.parent.current.height = h;
        let im_w = image.width;
        let im_h = image.height;
        // Set the maximum width and height
        const maxWidth = 800;
        const maxHeight = 500;
        let newWidth = im_w;
        let newHeight = im_h;
        // Calculate the aspect ratio
        const aspectRatio = image.width / image.height;
        if (im_w > maxWidth) {
          // Calculate the new width and height while maintaining the aspect ratio
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
          }
        }
        if (im_h > maxHeight && newHeight > maxHeight) {
          // Calculate the new width and height while maintaining the aspect ratio
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;

          if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
          }
        }

        console.log("h,w", h, w, image.width, image.height);
        appCtx.ctxImage.drawImage(
          image,
          (w - newWidth) / 2,
          (h - newHeight) / 2,
          newWidth,
          newHeight
        );
        /*(w - image.width) / 2,
          (h - image.height) / 2, */
      };
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  };

  const handleClick = (ev) => {
    if (ev.target.id === "fichier") {
      setIsFichier(!isFichier);
      setIsFenetres(false);
    }
    if (ev.target.id === "enregistrer") {
      saveFile();
      setIsFichier(!isFichier);
    }
    if (ev.target.id === "ouvrir") {
      loadFile();
      setIsFichier(!isFichier);
    }
    if (ev.target.id === "importer") {
      loadImage();
      setIsFichier(!isFichier);
    }
    if (ev.target.id === "fenetres") {
      setIsFenetres(!isFenetres);
      setIsFichier(false);
    }
    if (ev.target.id === "outils") {
      setIsFenetres(!isFenetres);
      setToolbar();
    }
    if (ev.target.id === "tuto") {
      setIsFenetres(!isFenetres);
      setStepBar();
    }
    if (ev.target.id === "echelle") {
      appCtx.setShowEchelle(!appCtx.showEchelle);
    }
  };

  return (
    <div className="menu">
      <div className="logo">
        <img src={icon} alt="logo" />
      </div>
      <div className="nav-bar">
        <ul>
          <li>
            <div className="relative">
              <div
                id="fichier"
                className="menu-i fulls"
                onClick={(ev) => handleClick(ev)}
              >
                Fichier
              </div>
              {isFichier && (
                <div className="menu-box flex flex-col">
                  {false && (
                    <div id="nouveau" className=" menu-box-item px-4 py-1 mb-1">
                      Nouveau
                    </div>
                  )}
                  <div
                    onClick={(ev) => handleClick(ev)}
                    id="ouvrir"
                    className=" menu-box-item px-4 py-1 mb-1"
                  >
                    Ouvrir
                  </div>
                  <div
                    onClick={(ev) => handleClick(ev)}
                    id="importer"
                    className=" menu-box-item px-4 py-1 mb-1 w-full"
                  >
                    Importer image
                  </div>
                  <div
                    id="enregistrer"
                    className=" menu-box-item px-4 py-1 mb-1"
                    onClick={(ev) => handleClick(ev)}
                  >
                    Enregistrer sous
                  </div>
                </div>
              )}
            </div>
          </li>
          <li>
            <div className="relative">
              <div
                id="fenetres"
                className="menu-i fulls"
                onClick={(ev) => handleClick(ev)}
              >
                Fenêtres
              </div>
              {isFenetres && (
                <div className="menu-box flex flex-col">
                  <div
                    id="outils"
                    onClick={(ev) => handleClick(ev)}
                    className=" menu-box-item px-4 py-1 mb-1"
                  >
                    Barre d'outils
                  </div>
                  <div
                    onClick={(ev) => handleClick(ev)}
                    id="tuto"
                    className=" menu-box-item px-4 py-1 mb-1"
                  >
                    Tutoriels
                  </div>
                </div>
              )}
            </div>
          </li>
          <li>
            <div
              id="echelle"
              className="menu-i fulls"
              onClick={(ev) => handleClick(ev)}
            >
              Echelle
            </div>
          </li>
          {false && (
            <li>
              <label className="" htmlFor="upload_image">
                File
              </label>
              <input
                id="upload_image"
                className="hideInput"
                type="file"
                name="image"
                onChange={(ev) => handleChange(ev)}
              />
            </li>
          )}
          {false && (
            <li>
              <a href="">Edit</a>
            </li>
          )}
          {false && (
            <li>
              <a href="">Image</a>
            </li>
          )}
          {false && (
            <div>
              <li>
                <a href="">Layer</a>
              </li>
              <li>
                <a href="">Type</a>
              </li>
              <li>
                <a href="">Select</a>
              </li>
              <li>
                <a href="">Filter</a>
              </li>
              <li>
                <a href="">View</a>
              </li>
            </div>
          )}
        </ul>
      </div>
      <div className="menu-icons">
        <div className="container-icons">
          <a onClick={() => toggleFullScreen(document.body)} id="fullscreen">
            <img src={max} alt="" className="fulls" />
          </a>
        </div>
        <div className="container-icons ">
          <a
            onClick={() => {
              appCtx.setLoggedIn(false);
              localStorage.removeItem("loggedIn");
              //localStorage.removeItem("state");
            }}
          >
            <img src={cross} alt="" className="hover:bg-red-500" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
