import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./stepbar.css";
import cross from "../../assets/cross.png";
import "bootstrap";
import "../../../node_modules/plain-draggable/plain-draggable.min.js";
import "../../../node_modules/@icon/dashicons/dashicons.css";
import AppContext from "../../appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faForward } from "@fortawesome/free-solid-svg-icons";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

import { tutos } from "../../tutoriel/cleftlip";
import Draggable from "react-draggable";
import $ from "../../../node_modules/jquery/dist/jquery.min.js";

const Stepbar = () => {
  const appCtx = useContext(AppContext);
  const handleClick = () => {
    $("#stepbar").css({
      display: "none",
    });
  };
  return (
    <Draggable>
      <div id="stepbar" className="step">
        <div className="steptop relative">
          <img
            onClick={handleClick}
            className="crosstep cross"
            src={cross}
            alt=""
          />
        </div>
        <div className="step-cont py-2">
          {appCtx.step === -1 && (
            <div className="text-step">VISUALISER LE TUTORIEL</div>
          )}
          {appCtx.step !== -1 && (
            <div className="text-step">{tutos[appCtx.step]}</div>
          )}
          {appCtx.step !== -1 && false && (
            <div className="btn-container">
              <button className="btn-valid btn-annuler">Annuler</button>
              <button className="btn-valid">Valider</button>
            </div>
          )}
          {appCtx.step === -1 && (
            <div className="btn-container">
              <button
                onClick={() => {
                  appCtx.setStep(0);
                }}
                className="btn-valid"
              >
                Demarrer
              </button>
            </div>
          )}
          {appCtx.step !== -1 && (
            <div
              className="next-icon"
              onClick={() => {
                if (appCtx.step < tutos.length - 1)
                  appCtx.setStep(appCtx.step + 1);
                else if (appCtx.step === tutos.length - 1) appCtx.setStep(-1);
              }}
            >
              <FontAwesomeIcon icon={faForward} />
            </div>
          )}
          {appCtx.step !== -1 && (
            <div
              className="back-icon"
              onClick={() => {
                if (appCtx.step > 0) appCtx.setStep(appCtx.step - 1);
                else if (appCtx.step === 0) appCtx.setStep(-1);
              }}
            >
              <FontAwesomeIcon icon={faBackward} />
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default Stepbar;
