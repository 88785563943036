export const tutos = [
  "Marquer la ligne médiane à la base columellaire",
  "Marquer la hauteur de la colonne philtrale non fendue à la base columellaire.",
  "Marquer la hauteur de la colonne philtrale fendue à la base columellaire. Il s'agit d'une image miroir de la marque précédente - un étrier peut être utilisé.",
  "Marquez le creux (nadir) de l'arc de Cupidon.",
  "Marquez le sommet de l'arc de Cupidon sur le côté non fendu.",
  "Mesurez la distance entre ces points et transposez pour créer l'autre pic de l'arc de Cupidon.",
  "Au-dessus de chaque pointe d'arc de Cupidon, placez une marque à la jonction rouleau-cutanée blanche.",
  "Marquez une proposition d'incision cutanée. Ce point est à 1-2 mm du sommet du côté fendu de l'arc de Cupidon et orienté perpendiculairement à la colonne philtrale. Cette coupe arrière recevra un petit triangle cutané de l'élément de la lèvre latérale pour allonger l'aspect latéral fendu de l'élément de la lèvre médiale si cela est nécessaire.",
  "Marquez la bordure humide-sec sur le vermillon. Ceci est perpendiculaire à chaque point qui a été marqué sur le rouleau blanc.",
  "Marquez une coupe arrière vermillon, qui recevra un triangle vermillon de la lèvre latérale.",
  "Marquez chaque base alaire. Dans la salle d'opération, la base alaire latérale de la lèvre peut être tournée manuellement pour assurer un marquage symétrique.",
  "Marquez le seuil de la narine sans fente. Cette zone a une convexité particulière.",
  "Marquez maintenant un point symétrique sur le plancher nasal dans la fente. Ce sera le site de la fermeture du plancher nasal. L'objectif est de produire un seuil de même largeur et une ouverture nasale de même taille et forme. Ce marquage peut être manipulé en cas d'insuffisance de tissu pour obtenir un plancher nasal symétrique.",
  "La hauteur totale des lèvres est mesurée du côté non fendu, de la base columellaire au rouleau blanc. Au bloc opératoire, cela se fait avec la lèvre au repos.",
  "La plus grande hauteur de la lèvre est mesurée du côté de la fente de l'élément de la lèvre médiale. Dans la salle d'opération, cela se fait avec une légère traction sur la lèvre pour donner une vraie longueur.",
  "La hauteur de la lèvre inférieure est calculée comme suit : hauteur totale de la lèvre – hauteur de la lèvre supérieure - 1 mm. Cette valeur sera la largeur de base du triangle cutané à partir de l'élément de la lèvre latérale.",
  "NB:Les marquages des éléments de la lèvre latérale seront plus variables que la lèvre médiale pour s'adapter aux différences de hauteur de la lèvre latérale.",
  "Marquez le rouleau blanc là où il a encore toute son épaisseur à un point où le vermillon a commencé à s'amincir (point de Noordhoff). Le rouleau blanc doit être normal et le vermillon doit conserver une épaisseur proche de la normale pour éviter une carence centrale en vermillon.",
  "Le rouleau blanc est alors marqué juste au-dessus.",
  "Mesurez la largeur du plancher nasal non fendu à partir des marques précédentes.",
  "Marquez cette même largeur depuis la base alaire du côté de la fente jusqu'au plancher nasal.",
  "Mesurez la largeur du seuil nasal du côté de la fente avec un pied à coulisse et utilisez cette mesure pour localiser un point sur l'élément de la lèvre latérale.",
  "Marquez la base du triangle cutané à partir des mesures précédentes de hauteur totale/grande/petite lèvre.",
  "Ce triangle sera relié au point que vous avez précédemment marqué. Et cette distance correspondra à la colonne philtrale du côté fendu.",
  "Marquez la bordure humide-sèche du côté de la fente.",
  "Marquez un triangle isocèle de vermillon pour qu'il s'insère dans la coupe arrière précédemment conçue.",
  "Les marquages finaux peuvent être vus ici.",
];

export const tutos_english = [
  "Mark midline at the columellar base.",
  "Mark the height of the non-cleft philtral column at the columellar base.",
  "Mark the height of the cleft philtral column at the columellar base. This is a mirror image of the previous mark – caliper may be used.",
  "Mark the trough (nadir) of cupid’s bow.",
  "Mark the peak of cupid’s bow on the non-cleft side.",
  "Measure the distance between these points and transpose to create the other peak of cupid’s bow.",
  "Above each cupid’s bow peak place a mark at the white roll-cutaneous junction.",
  "Mark a proposed cutaneous back cut. This point is 1-2mm from the cleft side peak of cupid’s bow and oriented perpendicularly to the philtral column. This back cut will receive a small cutaneous triangle from the lateral lip element to lengthen the cleft side aspect of the medial lip element if that is necessary.",
  "Mark the wet-dry border on the vermilion. This is perpendicular to each point that was marked on the white roll.",
  "Mark a vermillion back cut, which will receive a vermillion triangle from the lateral lip.",
  "Mark each alar base. In the operating room, the lateral lip side alar base can be manually rotated in to ensure symmetric marking.",
  "Mark the non-cleft nostril sill. This area has a distinctive convexity.",
  "Now mark a symmetric point on the nasal floor in the cleft. This will be the site of nasal floor closure. The aim is to produce a sill of the same width and a nasal aperture of the same size and shape. This marking can be manipulated in the event that there is insufficient tissue to obtain a symmetric nasal floor.",
  "Total lip height is measured on the non-cleft side from the columellar base to the white roll. In the operating room this is done with the lip at rest.",
  "Greater lip height is measured on the cleft side of the medial lip element. In the operating room this is done with gentle traction on the lip to give a true length.",
  "Lesser lip height is calculated by the following: Total lip height – greater lip height – 1mm. This value will be the base width of the cutaneous triangle from the lateral lip element.",
  "NB: Lateral lip element markings will be more variable than the medial lip to accommodate for differences in lateral lip height.",
  "Mark the white roll where it still has full thickness at a point where the vermillion has begun to thin (Noordhoff’s point). The white roll should be normal and the vermillion should retain near normal thickness to avoid central vermillion deficiency.",
  "The white roll is then marked just above.",
  "Measure the non-cleft nasal floor width from the prior marks.",
  "Mark this same width from the cleft side alar base to the nasal floor.",
  "Measure the cleft side nasal sill width with a caliper and use this measurement to locate a point on the lateral lip element.",
  "Mark the base of the cutaneous triangle from the previous measurements of total/greater/lesser lip height.",
  "This triangle will be connected to the point that you previously marked. And this distance will match the cleft side philtral column.",
  "Mark the wet-dry border on the cleft side.",
  "Mark an isosceles triangle of vermillion to fit into the previously designed back cut.",
  "The final markings can be seen here.",
];
