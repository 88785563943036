import React, { useState } from "react";
import Menu from "../menu/menu";
import PageTab from "../pagetab/pagetab";
import Toolbar from "../toolbar/toolbar";

import { $, jQuery } from "../../../node_modules/jquery/dist/jquery.min.js";
import ReactFullpage from "@fullpage/react-fullpage";
import "./fullpage.css";
import { appFields } from "../../utils/utils";
import Stepbar from "../stepbar/stepbar";

const Fullpage = () => {
  const appDataFields = Object.freeze(appFields);
  const [appData, setAppData] = useState(appDataFields);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const files = e.target.files;
    setAppData({
      ...appData,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <ReactFullpage
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <Menu handleChange={handleInputChange}></Menu>
              <PageTab></PageTab>
              <Toolbar></Toolbar>
              <Stepbar></Stepbar>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default Fullpage;
