/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from "react";

export default function useAppContext() {
  const [xline, setXline] = useState();
  const [xline1, setXline1] = useState();
  const [yline, setYline] = useState();
  const [yline1, setYline1] = useState();
  const [ylineLast, setYlineLast] = useState();
  const [xlineLast, setXlineLast] = useState();
  const [ctx, setCtx] = useState();
  const [line, setLine] = useState(false);
  const [scale, setScale] = useState();
  const [ruler, setRuler] = useState(false);
  const [lineEnd, setLineEnd] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isDrawPoint, setIsDrawPoint] = useState(false);
  const [ctx2, setCtx2] = useState();
  const [ctx3, setCtx3] = useState();
  const [ctx4, setCtx4] = useState();
  const [ctxImage, setCtxImage] = useState();
  const [zIndex1, setZIndex1] = useState(3);
  const [zIndex2, setZIndex2] = useState(2);
  const [zIndex3, setZIndex3] = useState(1);
  const [zIndex4, setZIndex4] = useState(4);
  const [zIndex5, setZIndex5] = useState(0);
  const [distance, setDistance] = useState(0);
  const [distanceMesured, setDistanceMesured] = useState(0);
  const [mesure, setMesure] = useState(0);
  const [transposer, setTransposer] = useState(false);
  const [brush, setBrush] = useState(false);
  const [echelle, setEchelle] = useState(false);
  const [step, setStep] = useState(-1);
  const [eraser, setEraser] = useState(false);
  const [eraserLine, setEraserLine] = useState(false);
  const [tracer, setTracer] = useState(false);
  const [arrayPoints, setArrayPoints] = useState([]);
  const [arrayLines, setArrayLines] = useState([]);
  const [activeColor, setActiveColor] = useState("skyblue");
  const canvasImage = useRef();
  const canvasParent = useRef();
  const canvas = useRef();
  const canvas2 = useRef();
  const canvas3 = useRef();
  const parent = useRef();
  const [message, setMessage] = useState({
    visible: false,
    message: "",
    type: "",
  });
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [showToolbar, setShowToolbar] = useState(true);
  const [showTuto, setShowTuto] = useState(false);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedIn"));
  const [isEchelle, setIsEchelle] = useState(false);
  const [showEchelle, setShowEchelle] = useState(false);
  // draw a line
  const drawLine = (info) => {
    const { x, y, x1, y1, style = {} } = info;
    const { color = activeColor, width = 2 } = style;
    console.log("drawing line canevas2");
    ctx.beginPath();
    //ctx.setLineDash([5, 15]);
    ctx.moveTo(x, y);
    ctx.lineTo(x1, y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();
  };

  const drawLine2 = (info) => {
    const { x, y, x1, y1, style = {} } = info;
    const { color = activeColor, width = 2 } = style;
    console.log("drawing line");
    ctx2.beginPath();
    //ctx.setLineDash([5, 15]);
    ctx2.moveTo(x, y);
    ctx2.lineTo(x1, y1);
    ctx2.strokeStyle = color;
    ctx2.lineWidth = width;
    ctx2.stroke();
  };

  const drawLine4 = (info) => {
    const { p1, p2, style = {} } = info;
    const { color = activeColor, width = 2 } = style;
    console.log("drawing line on ctx3");
    ctx4.beginPath();
    //ctx.setLineDash([5, 15]);
    ctx4.moveTo(p1.x, p1.y);
    ctx4.lineTo(p2.x, p2.y);
    ctx4.strokeStyle = color;
    ctx4.lineWidth = width;
    ctx4.stroke();
  };

  const drawLine3 = (info) => {
    const { p1, p2, style = {} } = info;
    const { color = activeColor, width = 2 } = style;
    console.log("drawing line on ctx3");
    ctx3.beginPath();
    //ctx.setLineDash([5, 15]);
    ctx3.moveTo(p1.x, p1.y);
    ctx3.lineTo(p2.x, p2.y);
    ctx3.strokeStyle = color;
    ctx3.lineWidth = width;
    ctx3.stroke();
  };

  const drawCircle2 = (info) => {
    const { x: centerX, y: centerY, style = {} } = info;
    const { color = activeColor, width = 1 } = style;
    let radius = 2.5;

    ctx2.beginPath();
    ctx2.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx2.fillStyle = color;
    ctx2.fill();
    ctx2.lineWidth = width;
    ctx2.strokeStyle = "#FFF";
    ctx2.stroke();
  };

  const erase = (info, style = {}) => {
    //console.log("removing point ...", xline, yline);
    if (determinePointClicked({ x: xline + 10, y: yline + 10 })) {
      console.log(
        "removing point ...",
        determinePointClicked({ x: xline + 10, y: yline + 10 })
      );
      removeLine({ x: xline + 10, y: yline + 10 });
    }
    removePoint(determinePointClicked({ x: xline + 10, y: yline + 10 }));

    ctx.clearRect(xline + 5, yline + 5, 8, 8);
    ctx2.clearRect(xline + 5, yline + 5, 8, 8);
  };

  const drawCircle = (info) => {
    const { x: centerX, y: centerY, style = {} } = info;
    const { color = activeColor, width = 1 } = style;
    let radius = 2.5;

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.lineWidth = width;
    ctx.strokeStyle = "#FFF";
    ctx.stroke();
  };

  const drawCircle4 = (info) => {
    const { x: centerX, y: centerY, style = {} } = info;
    const { color = activeColor, width = 1 } = style;
    let radius = 2.5;

    ctx4.beginPath();
    ctx4.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx4.fillStyle = color;
    ctx4.fill();
    ctx4.lineWidth = width;
    ctx4.strokeStyle = "#FFF";
    ctx4.stroke();
  };

  useEffect(() => {
    let mounted = true;

    async function draw() {
      drawLine2({
        x: xline,
        y: yline,
        x1: xline1,
        y1: yline1,
      });
    }
    // console.log('User in hook use user ', userId);
    if (lineEnd) {
      draw();
      setLineEnd(false);
    }
    return () => (mounted = false);
  }, [lineEnd]);

  useEffect(() => {
    let mounted = true;
    // console.log('User in hook use user ', userId);
    if (eraser) {
      erase();
      setEraser(false);
    }
    return () => (mounted = false);
  }, [eraser]);

  useEffect(() => {
    let mounted = true;

    async function draw() {
      drawCircle({
        x: xline,
        y: yline,
      });
      setArrayPoints([
        ...arrayPoints,
        {
          x: xline,
          y: yline,
          color: activeColor,
        },
      ]);
    }
    async function draw2() {
      drawCircle({
        x: xline1,
        y: yline1,
      });
      setArrayPoints([
        ...arrayPoints,
        {
          x: xline1,
          y: yline1,
          color: activeColor,
        },
      ]);
    }
    // console.log('User in hook use user ', userId);
    if (isDrawPoint) {
      if (transposer) {
        if (
          !determinePointClicked({
            x: xline1,
            y: yline1,
          })
        ) {
          draw2();
        } else
          setMessage({
            visible: true,
            message: "Ce point existe déjà tu ne peut pas le marquer deux fois",
            type: "error",
          });
      } else {
        if (
          !determinePointClicked({
            x: xline,
            y: yline,
          })
        ) {
          draw();
        } else
          setMessage({
            visible: true,
            message: "Ce point existe déjà tu ne peut pas le marquer deux fois",
            type: "error",
          });
      }
      setIsDrawPoint(false);
    }
    return () => (mounted = false);
  }, [isDrawPoint]);

  useEffect(() => {
    console.log("arraypoints", arrayPoints);
  }, [arrayPoints]);

  useEffect(() => {
    let mounted = true;

    async function getContext() {}
    // console.log('User in hook use user ', userId);
    getContext();
    return () => (mounted = false);
  }, []);
  const computeDistance = (p1, p2) => {
    return Math.sqrt(
      (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)
    );
  };

  const determinePointClicked = (p1) => {
    let point = null;
    for (let i = 0; i < arrayPoints.length; i++) {
      const p2 = arrayPoints[i];
      if (
        Math.sqrt(
          (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)
        ) < 5
      ) {
        point = p2;
        break;
      }
    }
    return point;
  };

  const determineLineTraced = (l1) => {
    let line = null;
    for (let i = 0; i < arrayLines.length; i++) {
      const l2 = arrayLines[i];
      if (
        (l1.p1.x === l2.p1.x &&
          l1.p1.y === l2.p1.y &&
          l1.p2.x === l2.p2.x &&
          l1.p2.y === l2.p2.y) ||
        (l1.p1.x === l2.p2.x &&
          l1.p1.y === l2.p2.y &&
          l1.p2.x === l2.p1.x &&
          l1.p2.y === l2.p1.y)
      ) {
        line = l2;
        break;
      }
    }
    return line;
  };

  const removePoint = (p1) => {
    if (p1) {
      const points = arrayPoints.filter((p2) => p2.x !== p1.x || p2.y !== p1.y);
      setArrayPoints([...points]);
    }
  };

  function distanceToLine(A, B, C, x, y) {
    return Math.abs(A * x + B * y + C) / Math.sqrt(A * A + B * B);
  }

  const isPointOnLine = (p, l) => {
    const m = (l.p2.y - l.p1.y) / (l.p2.x - l.p1.x);
    const b = l.p1.y - m * l.p1.x;
    const dist = distanceToLine(-m, 1, -b, p.x, p.y);
    return dist < 5;
  };

  const removeLine = (p1) => {
    if (p1) {
      const lines = arrayLines.filter((line) => !isPointOnLine(p1, line));
      setArrayLines([...lines]);
    }
  };

  return {
    xline,
    xline1,
    yline,
    yline1,
    setXline,
    setXline1,
    setYline,
    setYline1,
    drawLine,
    drawLine2,
    setCtx,
    line,
    setLine,
    clicked,
    setClicked,
    lineEnd,
    setLineEnd,
    ctx,
    drawCircle,
    drawCircle2,
    setIsDrawPoint,
    ruler,
    setRuler,
    ctx2,
    setCtx2,
    zIndex1,
    setZIndex1,
    zIndex2,
    setZIndex2,
    computeDistance,
    distance,
    setDistance,
    transposer,
    setTransposer,
    distanceMesured,
    setDistanceMesured,
    brush,
    setBrush,
    zIndex3,
    setZIndex3,
    ctxImage,
    setCtxImage,
    step,
    setStep,
    arrayPoints,
    setArrayPoints,
    arrayLines,
    setArrayLines,
    eraser,
    setEraser,
    tracer,
    setTracer,
    message,
    setMessage,
    // draw a line
    determinePointClicked,
    ctx3,
    setCtx3,
    drawLine3,
    zIndex4,
    setZIndex4,
    determineLineTraced,
    removeLine,
    eraserLine,
    setEraserLine,
    dimension,
    setDimension,
    canvasImage,
    showToolbar,
    setShowToolbar,
    showTuto,
    setShowTuto,
    canvasParent,
    canvas,
    canvas2,
    canvas3,
    parent,
    activeColor,
    setActiveColor,
    loggedIn,
    setLoggedIn,
    echelle,
    setEchelle,
    mesure,
    setMesure,
    isEchelle,
    setIsEchelle,
    ctx4,
    setCtx4,
    zIndex5,
    setZIndex5,
    drawCircle4,
    drawLine4,
    scale,
    setScale,
    showEchelle,
    setShowEchelle,
  };
}
