import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../appContext";
import "./login.css";

function EntryPage() {
  const [currentView, setCurrentView] = useState("logIn");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({
    visible: false,
    message: "",
    type: "",
  });
  const appCtx = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (ev) => {
    ev.preventDefault();
    setMessage({
      visible: false,
      message: "",
      type: "",
    });
    if (username === "dr_obadi" && password === "cleft2023") {
      appCtx.setLoggedIn(true);
      localStorage.setItem("loggedIn", true);
    } else {
      setMessage({
        visible: true,
        message: "Données d'authentification incorrectes",
        type: "error",
      });
    }
  };
  const handleInput = (ev) => {
    ev.preventDefault();
    if (ev.target.id === "username") setUsername(ev.target.value);
    if (ev.target.id === "password") setPassword(ev.target.value);
  };
  const loadcurrentView = () => {
    switch (currentView) {
      case "signUp":
        return (
          <form>
            <h2>Sign Up!</h2>
            <fieldset>
              <legend>Create Account</legend>
              <ul>
                <li>
                  <label for="username">Username:</label>
                  <input type="text" id="username" required />
                </li>
                <li>
                  <label for="email">Email:</label>
                  <input type="email" id="email" required />
                </li>
                <li>
                  <label for="password">Password:</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    required
                  />
                </li>
              </ul>
            </fieldset>
            <button>Submit</button>
            <button type="button" onClick={() => setCurrentView("logIn")}>
              Have an Account?
            </button>
          </form>
        );
        break;
      case "logIn":
        return (
          <form>
            <h2>Bienvenu !</h2>
            <fieldset>
              <legend>Connexion</legend>
              {message.visible && (
                <div className="bg-red-trans">{message.message}</div>
              )}
              <ul>
                <li>
                  <label for="username">Nom utilisateur:</label>
                  <input
                    onChange={handleInput}
                    value={username}
                    type="text"
                    id="username"
                    required
                  />
                </li>
                <li>
                  <label for="password">Mot de passe:</label>
                  <div>
                    <input
                      value={password}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      required
                      onChange={handleInput}
                      className="mr-2"
                    />
                    <i
                      onClick={() => setShowPassword(!showPassword)}
                      className={
                        showPassword ? "far fa-eye-slash" : "far fa-eye"
                      }
                    />
                  </div>
                </li>
                {false && (
                  <li>
                    <i />
                    <a onClick={() => setCurrentView("PWReset")} href="#">
                      Forgot Password?
                    </a>
                  </li>
                )}
              </ul>
            </fieldset>
            <button onClick={handleLogin}>Login</button>
            {false && (
              <div>
                <button type="button" onClick={() => setCurrentView("signUp")}>
                  Create an Account
                </button>
              </div>
            )}
          </form>
        );
        break;
      case "PWReset":
        return (
          <form>
            <h2>Reset Password</h2>
            <fieldset>
              <legend>Password Reset</legend>
              <ul>
                <li>
                  <em>A reset link will be sent to your inbox!</em>
                </li>
                <li>
                  <label for="email">Email:</label>
                  <input type="email" id="email" required />
                </li>
              </ul>
            </fieldset>
            <button>Send Reset Link</button>
            <button type="button" onClick={() => setCurrentView("logIn")}>
              Go Back
            </button>
          </form>
        );
      default:
        break;
    }
  };

  return <section id="entry-page">{loadcurrentView()}</section>;
}
export default EntryPage;
