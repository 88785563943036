import React, { useContext, useEffect, useRef, useState } from "react";
import "./pagetab.css";
import cross from "../../assets/cross.png";
import "../../../node_modules/bootstrap/dist/js/bootstrap";
import {
  Tab,
  Row,
  Col,
  Nav,
  NavItem,
} from "../../../node_modules/react-bootstrap/dist/react-bootstrap";
import "../../../node_modules/plain-draggable/plain-draggable.min.js";
import "../../../node_modules/@icon/dashicons/dashicons.css";
import AppContext from "../../appContext";
import face from "../../assets/faces/face.png";
import ModalMessage from "../modal";
import ModalEchelle from "../menu/modal";

const PageTab = () => {
  const { canvasImage, canvasParent, canvas, canvas2, canvas3, parent } =
    useContext(AppContext);

  const appCtx = useContext(AppContext);
  const [p1Line, setP1Line] = useState(null);

  // initialize the canvas context
  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    const canvasEle2 = canvas2.current;
    const canvasEle3 = canvas3.current;
    const canvasEchelle = parent.current;
    const image = new Image();
    image.src = face;
    canvasParent.current.width = 900;
    canvasParent.current.height = 500;

    canvasEle.width = canvasParent.current.clientWidth;
    canvasEle.height = canvasParent.current.clientHeight;
    canvasEle2.width = canvasParent.current.clientWidth;
    canvasEle2.height = canvasParent.current.clientHeight;
    canvasEle3.width = canvasParent.current.clientWidth;
    canvasEle3.height = canvasParent.current.clientHeight;
    canvasEchelle.width = canvasParent.current.clientWidth;
    canvasEchelle.height = canvasParent.current.clientHeight;

    console.log("CANEVAS ", canvasEle);

    // get context of the canvas
    appCtx.setCtx(canvasEle.getContext("2d"));
    appCtx.setCtx2(canvasEle2.getContext("2d"));
    appCtx.setCtx3(canvasEle3.getContext("2d"));
    appCtx.setCtx4(canvasEchelle.getContext("2d"));
    appCtx.setCtxImage(canvasImage.current.getContext("2d"));
  }, []);

  const resetTracer = (msg) => {
    setP1Line(null);
    appCtx.setClicked(false);
    appCtx.ctx3.clearRect(0, 0, canvas3.current.width, canvas3.current.height);
    appCtx.arrayLines.forEach((line) => {
      appCtx.drawLine3({
        p1: line.p1,
        p2: line.p2,
        style: { color: line.color },
      });
    });
    appCtx.setMessage({
      visible: true,
      type: "error",
      message: msg,
    });
  };

  const resetTracerWithoutMessage = () => {
    appCtx.ctx3.clearRect(0, 0, canvas3.current.width, canvas3.current.height);
    appCtx.arrayLines.forEach((line) => {
      appCtx.drawLine3({
        p1: line.p1,
        p2: line.p2,
        style: { color: line.color },
      });
    });
  };

  useEffect(() => {
    if (appCtx.eraserLine || appCtx.brush) {
      appCtx.ctx3.clearRect(
        0,
        0,
        canvas3.current.width,
        canvas3.current.height
      );
      appCtx.arrayLines.forEach((line) => {
        appCtx.drawLine3({
          p1: line.p1,
          p2: line.p2,
          style: { color: line.color },
        });
      });
    }
  }, [appCtx.arrayLines]);

  useEffect(() => {
    /**
     * if clicked on canvas
     */
    function handleClickOnCanvas(event) {
      //console.log("handle cclick outside1", event.target);
      console.log("clicked on canvas ", event);
      console.log("clicked on current ", canvas);
      console.log("lined? ", appCtx.line);
      console.log("clicked ? ", appCtx.clicked);
      if (appCtx.line && !appCtx.clicked) {
        console.log("clicked on canvas first", event);
        appCtx.setXline(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline(event.layerY - canvas.current.offsetTop);
        appCtx.setClicked(true);
        appCtx.setLineEnd(false);
      }
      if (appCtx.line && appCtx.clicked) {
        console.log("clicked on canvas second", event);
        appCtx.setXline1(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline1(event.layerY - canvas.current.offsetTop);
        appCtx.setClicked(false);
        appCtx.setLineEnd(true);
      }
    }

    function handlePointOnCanvas(event) {
      //set the coordinate to draw the point on the canvas
      if (appCtx.line) {
        appCtx.setXline(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline(event.layerY - canvas.current.offsetTop);
        appCtx.setIsDrawPoint(true);
      }
      /*if the ruler or the tacer is selected and the canevas not clicked
      set clicked to true and set the cordinate of the point where to start the
      line
      */
      if (appCtx.ruler && !appCtx.clicked) {
        appCtx.setClicked(true);
        appCtx.setXline(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline(event.layerY - canvas.current.offsetTop);
        appCtx.setLineEnd(false);
        //appCtx.setIsDrawPoint(true);
      }
      if (appCtx.tracer && !appCtx.clicked) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        const p1 = appCtx.determinePointClicked({ x: x, y: y });
        if (p1) {
          setP1Line(p1);
          appCtx.setClicked(true);
        } else {
          appCtx.setMessage({
            visible: true,
            type: "error",
            message:
              "Veuillez commencer la ligne à un point que vous avez déjà marqué",
          });
        }
      }

      if (appCtx.isEchelle && !appCtx.clicked) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        setP1Line({ x: x, y: y });
        appCtx.drawCircle4({ x: x, y: y });
        appCtx.setClicked(true);
      }
      if (appCtx.isEchelle && appCtx.clicked) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        const p2 = { x: x, y: y };
        appCtx.ctx4.clearRect(
          0,
          0,
          parent.current.width,
          parent.current.height
        );

        appCtx.drawLine4({ p1: p1Line, p2: p2 });
        appCtx.drawCircle4(p1Line);
        appCtx.drawCircle4(p2);
        appCtx.setClicked(false);
        const dist = appCtx.computeDistance(p1Line, p2);
        appCtx.setDistance(dist.round(0));
        appCtx.setScale({ pixel: dist.round(0), p1: p1Line, p2: p2, mm: 0 });
        appCtx.setMesure(0);
        setP1Line(null);
        appCtx.setEchelle(true);
      }
      if (appCtx.eraserLine) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        appCtx.removeLine({ x: x, y: y });
      }
      if (appCtx.tracer && appCtx.clicked) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        const p2 = appCtx.determinePointClicked({ x: x, y: y });
        if (p2) {
          if (!appCtx.determineLineTraced({ p1: p1Line, p2: p2 })) {
            resetTracerWithoutMessage();
            appCtx.drawLine3({ p1: p1Line, p2: p2 });
            appCtx.setArrayLines([
              ...appCtx.arrayLines,
              { p1: p1Line, p2: p2, color: appCtx.activeColor },
            ]);
            appCtx.setClicked(false);
            setP1Line(null);
          } else {
            resetTracer("Cette ligne existe déjà");
          }
        } else {
          resetTracer(
            "Veuillez terminer la ligne à un point que vous avez déjà marqué"
          );
        }
      }
      //if the ruler is set and clicked set, clear everything on the second canevas
      if (appCtx.ruler && appCtx.clicked) {
        appCtx.ctx2.clearRect(
          0,
          0,
          canvas2.current.width,
          canvas2.current.height
        );
        appCtx.setXline1(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline1(event.layerY - canvas.current.offsetTop);
        appCtx.setLineEnd(false);
        const dist = appCtx.computeDistance(
          { x: appCtx.xline, y: appCtx.yline },
          { x: appCtx.xline1, y: appCtx.yline1 }
        );
        appCtx.setDistance(dist.round(0));
        appCtx.setClicked(false);
      }

      if (appCtx.transposer && !appCtx.clicked) {
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        if (appCtx.determinePointClicked({ x: x, y: y })) {
          appCtx.setClicked(true);
          appCtx.setDistanceMesured(0);
          appCtx.setXline(appCtx.determinePointClicked({ x: x, y: y }).x);
          appCtx.setYline(appCtx.determinePointClicked({ x: x, y: y }).y);
          appCtx.setLineEnd(false);
        } else {
          appCtx.setMessage({
            visible: true,
            type: "error",
            message:
              "Veuillez commencer la transposition de la distance à  partir d'un point que vous avez déjà marqué",
          });
        }
      }
      if (appCtx.transposer && appCtx.clicked) {
        appCtx.setLineEnd(false);
        appCtx.setIsDrawPoint(true);
        appCtx.setClicked(false);
        appCtx.ctx2.clearRect(
          0,
          0,
          canvas2.current.width,
          canvas2.current.height
        );
      }
      if (appCtx.brush) {
        console.log("APPCTX BRUSH");
        appCtx.setXline(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline(event.layerY - canvas.current.offsetTop);
        appCtx.setEraser(true);
      }
    }

    function handleMoveOnCanvas(event) {
      // console.log("handle move on canvaas", event);

      if (appCtx.ruler && appCtx.clicked) {
        appCtx.ctx2.clearRect(
          0,
          0,
          canvas2.current.width,
          canvas2.current.height
        );
        appCtx.setXline1(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline1(event.layerY - canvas.current.offsetTop);
        appCtx.setLineEnd(true);
        const dist = appCtx.computeDistance(
          { x: appCtx.xline, y: appCtx.yline },
          { x: appCtx.xline1, y: appCtx.yline1 }
        );
        appCtx.setDistance(dist.round(0));
        console.log("distance ", dist);
      }

      if (appCtx.tracer && appCtx.clicked) {
        appCtx.ctx3.clearRect(
          0,
          0,
          canvas3.current.width,
          canvas3.current.height
        );
        appCtx.arrayLines.forEach((line) => {
          appCtx.drawLine3({
            p1: line.p1,
            p2: line.p2,
            style: { color: line.color },
          });
        });

        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        const p2 = { x: x, y: y };

        appCtx.drawLine3({ p1: p1Line, p2: p2 });
      }

      if (appCtx.isEchelle && appCtx.clicked) {
        appCtx.ctx4.clearRect(
          0,
          0,
          parent.current.width,
          parent.current.height
        );
        const x = event.layerX - canvas.current.offsetLeft;
        const y = event.layerY - canvas.current.offsetTop;
        const p2 = { x: x, y: y };

        appCtx.drawLine4({ p1: p1Line, p2: p2 });
        appCtx.drawCircle4(p1Line);
        const dist = appCtx.computeDistance(p1Line, p2);
        appCtx.setDistance(dist.round(0));
      }

      if (appCtx.transposer && appCtx.clicked) {
        appCtx.ctx2.clearRect(
          0,
          0,
          canvas2.current.width,
          canvas2.current.height
        );
        appCtx.setXline1(event.layerX - canvas.current.offsetLeft);
        appCtx.setYline1(event.layerY - canvas.current.offsetTop);
        appCtx.setLineEnd(true);
        const dist = appCtx.computeDistance(
          { x: appCtx.xline, y: appCtx.yline },
          { x: appCtx.xline1, y: appCtx.yline1 }
        );
        appCtx.setDistanceMesured(dist.round(0));
        console.log("distance ", dist);
      }
    }

    // Bind the event listener
    canvas.current.addEventListener("mousedown", handlePointOnCanvas);
    canvas2.current.addEventListener("mousedown", handlePointOnCanvas);
    canvas2.current.addEventListener("mousemove", handleMoveOnCanvas);
    canvas3.current.addEventListener("mousedown", handlePointOnCanvas);
    canvas3.current.addEventListener("mousemove", handleMoveOnCanvas);
    parent.current.addEventListener("mousedown", handlePointOnCanvas);
    parent.current.addEventListener("mousemove", handleMoveOnCanvas);
    return () => {
      // Unbind the event listener on clean up
      if (canvas && canvas.current)
        canvas.current.removeEventListener("mousedown", handlePointOnCanvas);
      if (canvas2 && canvas2.current) {
        canvas2.current.removeEventListener("mousemove", handleMoveOnCanvas);
        canvas2.current.removeEventListener("mousedown", handlePointOnCanvas);
      }
      if (canvas3 && canvas3.current) {
        canvas3.current.removeEventListener("mousemove", handleMoveOnCanvas);
        canvas3.current.removeEventListener("mousedown", handlePointOnCanvas);
      }
      if (parent && parent.current) {
        parent.current.removeEventListener("mousemove", handleMoveOnCanvas);
        parent.current.removeEventListener("mousedown", handlePointOnCanvas);
      }
    };
  }, [appCtx]);
  useEffect(() => {
    console.log("line changed REALLY 2 ", appCtx.line);
  }, [appCtx.line]);

  Number.prototype.round = function (p) {
    p = p || 0;
    return parseFloat(this.toFixed(p));
  };

  async function draw_image() {
    const image = new Image();
    image.src = face;
    console.log("image src", image.width);
    console.log("image src2", image.height);
    console.log("canvas src", canvas.current.width);
    console.log("canvas src2", canvas.current.height);

    image.onload = () => {
      if (appCtx.ctx)
        appCtx.ctx.drawImage(
          image,
          (canvas.current.width - image.width) / 2,
          (canvas.current.height - image.height) / 2
        );
    };
    //(canvas.current.width - image.width) / 2
    //(canvas.current.height - image.height) / 2
  }

  useEffect(() => {
    async function put_image() {
      const image = new Image();
      image.src = face;
      console.log("image src", image.width);
      console.log("image src2", image.height);
      console.log("canvas src", canvas.current.width);
      console.log("canvas src2", canvas.current.height);

      image.onload = () => {
        if (appCtx.ctx)
          appCtx.ctxImage.drawImage(
            image,
            (canvasImage.current.width - image.width / 1.5) / 2,
            (canvasImage.current.height - image.height / 1.5) / 2,
            image.width / 1.5,
            image.height / 1.5
          );
        appCtx.setDimension({
          width: canvasImage.current.width,
          height: canvasImage.current.height,
        });
      };
    }
    false && put_image();
  }, [appCtx.ctxImage]);

  useEffect(() => {
    async function setShowScale() {
      appCtx.ctx4.clearRect(0, 0, parent.current.width, parent.current.height);

      appCtx.drawLine4({ p1: appCtx.scale.p1, p2: appCtx.scale.p2 });
      appCtx.drawCircle4(appCtx.scale.p1);
      appCtx.drawCircle4(appCtx.scale.p2);
    }
    if (appCtx.showEchelle && appCtx.ctx4 && appCtx.scale) setShowScale();
  }, [appCtx.showEchelle]);

  useEffect(() => {
    async function saveState() {
      const canvasDataUrl = appCtx.canvasImage.current.toDataURL();
      console.log("saving state ...");
      const imageData = {
        name: "myImage",
        type: "png",
        data: canvasDataUrl,
      };
      const file = {
        image: imageData,
        points: appCtx.arrayPoints,
        lines: appCtx.arrayLines,
      };

      const json = JSON.stringify(file);
      localStorage.setItem("state", json);
    }
    saveState();
  }, [appCtx.arrayLines, appCtx.arrayPoints]);

  useEffect(() => {
    let mounted = true;
    async function loadState() {
      let imageData = null;

      const json = localStorage.getItem("state");
      console.log("state", json);
      if (json) imageData = JSON.parse(json);

      if (imageData) {
        const image = new Image();
        image.src = imageData.image.data;
        console.log(imageData.image);

        image.onload = () => {
          appCtx.ctx.clearRect(0, 0, 1000, 1000);
          appCtx.ctx2.clearRect(0, 0, 1000, 1000);
          appCtx.ctxImage.clearRect(0, 0, 1000, 1000);
          appCtx.ctx3.clearRect(0, 0, 1000, 1000);
          appCtx.setArrayPoints([]);
          appCtx.setArrayLines([]);
          const h = appCtx.canvasParent.current.clientHeight;
          const w = appCtx.canvasParent.current.clientWidth;
          appCtx.canvasImage.current.width = w;

          appCtx.canvasImage.current.height = h;

          appCtx.canvas.current.width = w;
          appCtx.canvas.current.height = h;
          appCtx.canvas2.current.width = w;
          appCtx.canvas2.current.height = h;
          appCtx.canvas3.current.width = w;
          appCtx.canvas3.current.height = h;
          let im_w = image.width;
          let im_h = image.height;
          // Set the maximum width and height
          const maxWidth = 800;
          const maxHeight = 500;
          let newWidth = im_w;
          let newHeight = im_h;
          // Calculate the aspect ratio
          const aspectRatio = image.width / image.height;
          if (im_w > maxWidth) {
            // Calculate the new width and height while maintaining the aspect ratio
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = newHeight * aspectRatio;
            }
          }
          if (im_h > maxHeight && newHeight > maxHeight) {
            // Calculate the new width and height while maintaining the aspect ratio
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;

            if (newWidth > maxWidth) {
              newWidth = maxWidth;
              newHeight = newWidth / aspectRatio;
            }
          }

          console.log("h,w", h, w, image.width, image.height);
          appCtx.ctxImage.drawImage(
            image,
            (w - newWidth) / 2,
            (h - newHeight) / 2,
            newWidth,
            newHeight
          );

          const points = imageData.points;
          const lines = imageData.lines;
          appCtx.setArrayPoints([...points]);
          appCtx.setArrayLines([...lines]);
          lines.forEach((line) => {
            appCtx.drawLine3({
              p1: line.p1,
              p2: line.p2,
              style: { color: line.color },
            });
          });
          points.forEach((point) => {
            appCtx.drawCircle({
              x: point.x,
              y: point.y,
              style: { color: point.color },
            });
          });
        };
      }
    }

    /*const timer = setTimeout(() => {
      loadState();
    }, 2000);*/

    return () => {
      mounted = false;
      //clearTimeout(timer);
    };
  }, []);
  const convertDistance = (distance) => {
    return (
      (parseFloat(distance) * parseFloat(appCtx.scale.mm)) /
      parseFloat(appCtx.scale.pixel)
    );
  };
  return (
    <>
      <Tab.Container id="page-tab" defaultActiveKey="first">
        <Row className="clearfix">
          <Col sm={12}>
            {false && (
              <Nav bsStyle="tabs">
                <NavItem eventKey="first">
                  <span>Home </span>
                  <img className="tabcross" src={cross} alt="" />
                </NavItem>
              </Nav>
            )}
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {appCtx.ruler && (
                  <div className="box-1">
                    <span className="label-dist">Distance:&nbsp;</span>
                    <span className="dist">
                      {appCtx.distance.round(0)}
                      {"px /"}
                      {`${
                        appCtx.scale
                          ? convertDistance(appCtx.distance.round(0)).toFixed(
                              1
                            ) + "mm"
                          : "Parametrez l'echelle pour voir la distance en mm"
                      }`}
                    </span>
                  </div>
                )}
                {appCtx.transposer && (
                  <div className="box-1">
                    <div>
                      <span className="label-dist">Distance gardée:&nbsp;</span>
                      <span className="dist">
                        {appCtx.distance.round(0)}
                        {"px /"}
                        {`${
                          appCtx.scale
                            ? convertDistance(appCtx.distance.round(0)).toFixed(
                                1
                              ) + "mm"
                            : "Parametrez l'echelle pour voir la distance en mm"
                        }`}
                      </span>
                    </div>
                    <div>
                      <span className="label-dist">
                        Distance mesurée:&nbsp;
                      </span>
                      <span className="dist">
                        {appCtx.distanceMesured.round(0)}
                        {"px /"}
                        {`${
                          appCtx.scale
                            ? convertDistance(
                                appCtx.distanceMesured.round(0)
                              ).toFixed(1) + "mm"
                            : "Parametrez l'echelle pour voir la distance en mm"
                        }`}
                      </span>
                    </div>
                  </div>
                )}
                {appCtx.showEchelle && (
                  <div className="box-1">
                    <div>
                      {appCtx.scale ? (
                        <span className="label-dist">{`Echelle: ${appCtx.scale.pixel} pixels = ${appCtx.scale.mm} mm`}</span>
                      ) : (
                        <span className="label-dist">{`Pas d'échelle disponible. Cliquez sur E dans la barre d'outils puis parametrez`}</span>
                      )}
                    </div>
                  </div>
                )}
                <div>
                  <div ref={canvasParent} className="wbody">
                    <div className="canvas-class">
                      <div
                        className="canvas-abs1"
                        style={{ zIndex: appCtx.zIndex1 }}
                      >
                        <canvas ref={canvas}></canvas>
                      </div>
                      <div
                        className="canvas-abs2"
                        style={{ zIndex: appCtx.zIndex2 }}
                      >
                        <canvas ref={canvas2}></canvas>
                      </div>
                      <div
                        className="canvas-abs3"
                        style={{ zIndex: appCtx.zIndex3 }}
                      >
                        <canvas ref={canvasImage}></canvas>
                      </div>
                      <div
                        className="canvas-abs4"
                        style={{ zIndex: appCtx.zIndex4 }}
                      >
                        <canvas ref={canvas3}></canvas>
                      </div>
                      <div
                        className="canvas-abs5"
                        style={{ zIndex: appCtx.zIndex5 }}
                      >
                        <canvas ref={parent}></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="wfoot"></div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <ModalMessage />
          <ModalEchelle />
        </Row>
      </Tab.Container>
    </>
  );
};

export default PageTab;
