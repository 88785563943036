import React, { Component } from "react";
import Fullpage from "../src/components/fullpage/fullpage";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/popper.js/dist/popper.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { PlainDraggable } from "../node_modules/plain-draggable/plain-draggable.min.js";
import "./App.css";
import useAppContext from "./hooks/useAppContext";
import AppContext from "./appContext";
import EntryPage from "./components/login/login";

const App = () => {
  const appCont = useAppContext();

  return (
    <AppContext.Provider value={{ ...appCont }}>
      {appCont.loggedIn && <Fullpage />}
      {!appCont.loggedIn && <EntryPage />}
    </AppContext.Provider>
  );
};

export default App;
