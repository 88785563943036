import React, { useState } from "react";
import Modal from "react-modal";
import { useContext } from "react";
import AppContext from "../appContext";

function ModalMessage() {
  const { message, setMessage } = useContext(AppContext);
  return (
    <>
      <div className="flex flex-col border rounded-lg">
        <Modal
          isOpen={message.visible}
          onRequestClose={() =>
            setMessage({ visible: false, message: "", type: "" })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="px-4 pb-4">
            <div className="flex flex-row justify-center mb-8">
              <div className="text-2xl font-bold text-center">Message</div>
            </div>
            {message.visible && (
              <div
                className={`py-1 px-2 mt-2 ${
                  message.type === "success"
                    ? "bg-green-trans"
                    : "bg-red-trans text-white"
                } w-full rounded-md mb-4`}
              >
                {message.message}
              </div>
            )}

            <div className="flex flex-row justify-items-center justify-center">
              <div
                onClick={() =>
                  setMessage({ visible: false, message: "", type: "" })
                }
                className="text-center border  bg-green-500 py-1 px-4 rounded-full  mx-auto outline-none focus:outline-none cursor-pointer main-background "
              >
                OK
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ModalMessage;
