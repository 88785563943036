import React, { Component, useContext, useEffect, useState } from "react";
import "./toolbar.css";
import cross from "../../assets/cross.png";
import move from "../../assets/move.png";
import selection from "../../assets/selection.png";
import lasso from "../../assets/lasso.png";
import magic from "../../assets/pencil.png";
import pencil2 from "../../assets/pencil2.png";
import black_blur from "../../assets/black_blur.png";
import ruler from "../../assets/ruler.png";
import ruler_cursor from "../../assets/ruler-cursor.png";
import crop from "../../assets/crop.png";
import eye from "../../assets/eye.png";
import healing from "../../assets/healing.png";
import brush from "../../assets/brush.png";
import stamp from "../../assets/stamp.png";
import history from "../../assets/history.png";
import eraser from "../../assets/eraser.png";
import gradient from "../../assets/gradient.png";
import blur from "../../assets/blur.png";
import dodge from "../../assets/dodge.png";
import pen from "../../assets/pen.png";
import text from "../../assets/text.png";
import path from "../../assets/path.png";
import rectangle from "../../assets/rectangle.png";
import hand from "../../assets/hand.png";
import mag from "../../assets/mag.png";
import edit from "../../assets/edit.png";
import $ from "../../../node_modules/jquery/dist/jquery.min.js";
import Draggable from "react-draggable";
import AppContext from "../../appContext";
import { SketchPicker } from "react-color";

export default function Toolbar() {
  const appCtx = useContext(AppContext);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    var move = require("../../assets/move.png");
    var selection = require("../../assets/selection.png");
    var lasso = require("../../assets/lasso.png");
    var magic = require("../../assets/pencil.png");
    var pencil2 = require("../../assets/pencil2.png");
    var crop = require("../../assets/crop.png");
    var eye = require("../../assets/eye.png");
    var healing = require("../../assets/healing.png");
    var brush = require("../../assets/brush.png");
    var stamp = require("../../assets/stamp.png");
    var history = require("../../assets/history.png");
    var eraser = require("../../assets/eraser.png");
    var gradient = require("../../assets/gradient.png");
    var blur = require("../../assets/blur.png");
    var dodge = require("../../assets/dodge.png");
    var pen = require("../../assets/pen.png");
    var text = require("../../assets/text.png");
    var path = require("../../assets/path.png");
    var rectangle = require("../../assets/rectangle.png");
    var hand = require("../../assets/hand.png");
    var mag = require("../../assets/mag.png");
    $(".tmove").click(function () {
      $("*").css({
        cursor: "url(" + move + "),auto",
      });
    });
    $(".tselection").click(function () {
      $("*").css({
        cursor: "url(" + selection + "),auto",
      });
    });
    $(".tlasso").click(function () {
      $("*").css({
        cursor: "url(" + lasso + "),auto",
      });
    });

    $(".tcrop").click(function () {
      $("*").css({
        cursor: "url(" + crop + "),auto",
      });
    });
    $(".teye").click(function () {
      $("*").css({
        cursor: "url(" + eye + "),auto",
      });
    });
    $(".thealing").click(function () {
      $("*").css({
        cursor: "url(" + healing + "),auto",
      });
    });
    $(".tbrush").click(function () {
      $("*").css({
        cursor: "url(" + brush + "),auto",
      });
    });
    $(".tstamp").click(function () {
      $("*").css({
        cursor: "url(" + stamp + "),auto",
      });
    });
    $(".thistory").click(function () {
      $("*").css({
        cursor: "url(" + history + "),auto",
      });
    });
    $(".teraser").click(function () {
      $("*").css({
        cursor: "url(" + eraser + "),auto",
      });
    });
    $(".tgradient").click(function () {
      $("*").css({
        cursor: "url(" + pencil2 + "),auto",
      });
    });
    $(".tblur").click(function () {
      $("*").css({
        cursor: "url(" + blur + "),auto",
      });
    });
    $(".tdodge").click(function () {
      $("*").css({
        cursor: "url(" + dodge + "),auto",
      });
    });
    $(".tpen").click(function () {
      $("*").css({
        cursor: "url(" + pen + "),auto",
      });
    });
    $(".ttext").click(function () {
      $("*").css({
        cursor: "url(" + text + "),auto",
      });
    });
    $(".tpath").click(function () {
      $("*").css({
        cursor: "url(" + path + "),auto",
      });
    });
    $(".trectangle").click(function () {
      $("*").css({
        cursor: "url(" + rectangle + "),auto",
      });
    });
    $(".thand").click(function () {
      $("*").css({
        cursor: "url(" + hand + "),auto",
      });
    });
    $(".tmag").click(function () {
      $("*").css({
        cursor: "url(" + mag + "),auto",
      });
    });
  }, []);
  const handleCrossClick = () => {
    appCtx.setIsEchelle(false);
    $("#toolbar").css({
      display: "none",
    });
  };

  const tlineClicked = () => {
    appCtx.setIsEchelle(false);
    appCtx.setZIndex1(4);
    appCtx.setZIndex2(3);
    appCtx.setZIndex4(2);
    appCtx.setDistance(0);
    resetSelection();
    if (appCtx.line) {
      console.log("cursor magic true");
      $("*").css({
        cursor: "default",
      });
      $(".tmagic").css({
        background: "transparent",
      });
    } else {
      console.log("cursor magic false");
      $("*").css({
        cursor: "url(" + pencil2 + "),auto",
      });
      $(".tmagic").css({
        background: "#383838",
      });
    }

    if (appCtx.line === false) {
      appCtx.setClicked(false);
      console.log("setCicked toolbar");
    }
    appCtx.setLine(!appCtx.line);
    console.log("setting line ", !appCtx.line);
  };

  const trulerClicked = () => {
    appCtx.setIsEchelle(false);
    resetSelection();
    appCtx.setDistance(0);
    appCtx.setZIndex1(3);
    appCtx.setZIndex2(4);
    appCtx.setZIndex4(2);
    if (appCtx.ruler) {
      console.log("cursor ruler true");
      $("*").css({
        cursor: "default",
      });
      $(".truler").css({
        background: "transparent",
      });
    } else {
      console.log("cursor magic false");
      $("*").css({
        cursor: "url(" + ruler_cursor + "),auto",
      });
      $(".truler").css({
        background: "#383838",
      });
    }

    if (appCtx.ruler === false) {
      appCtx.setClicked(false);
      console.log("setCicked toolbar");
    }
    appCtx.setRuler(!appCtx.ruler);
    console.log("setting ruler", !appCtx.ruler);
  };

  const ttraceClicked = () => {
    appCtx.setIsEchelle(false);
    resetSelection();
    appCtx.setDistance(0);
    appCtx.setZIndex1(3);
    appCtx.setZIndex2(2);
    appCtx.setZIndex4(4);
    if (appCtx.tracer) {
      console.log("cursor tracer true");
      $("*").css({
        cursor: "default",
      });
      $(".ttracer").css({
        background: "transparent",
      });
    } else {
      console.log("cursor tracer false");
      $("*").css({
        cursor: "url(" + ruler_cursor + "),auto",
      });
      $(".ttracer").css({
        background: "#383838",
      });
    }

    if (appCtx.tracer === false) {
      appCtx.setClicked(false);
      console.log("setClicked toolbar tracer");
    }
    appCtx.setTracer(!appCtx.tracer);
    console.log("setting tracer", !appCtx.tracer);
  };

  const teraserLineClicked = () => {
    appCtx.setIsEchelle(false);
    resetSelection();
    appCtx.setDistance(0);
    appCtx.setZIndex1(3);
    appCtx.setZIndex2(2);
    appCtx.setZIndex4(4);
    if (appCtx.eraserLine) {
      console.log("cursor eraser line true");
      $("*").css({
        cursor: "default",
      });
      $(".teraserLine").css({
        background: "transparent",
      });
    } else {
      console.log("cursor tracer false");
      $("*").css({
        cursor: "url(" + pencil2 + "),auto",
      });
      $(".teraserLine").css({
        background: "#383838",
      });
    }

    if (appCtx.eraserLine === false) {
      appCtx.setClicked(false);
      console.log("setClicked toolbar eraserLine");
    }
    appCtx.setEraserLine(!appCtx.eraserLine);
    console.log("setting eraserLine", !appCtx.eraserLine);
  };

  const ttransposeClicked = () => {
    appCtx.setIsEchelle(false);
    resetSelection();
    appCtx.setZIndex1(3);
    appCtx.setZIndex2(4);
    appCtx.setZIndex4(2);
    if (appCtx.transposer) {
      console.log("cursor transpose true");
      $("*").css({
        cursor: "default",
      });
      $(".ttranspose").css({
        background: "transparent",
      });
    } else {
      console.log("cursor magic false");
      $("*").css({
        cursor: "url(" + ruler_cursor + "),auto",
      });
      $(".ttranspose").css({
        background: "#383838",
      });
    }

    if (appCtx.transpose === false) {
      appCtx.setClicked(false);
    }
    appCtx.setTransposer(!appCtx.transposer);
    console.log("setting transposer", !appCtx.transposer);
  };

  const tbrushClicked = () => {
    appCtx.setIsEchelle(false);
    resetSelection();
    appCtx.setZIndex1(3);
    appCtx.setZIndex2(4);
    appCtx.setZIndex4(2);
    if (appCtx.brush) {
      console.log("cursor brush true");
      $("*").css({
        cursor: "default",
      });
      $(".tbrush").css({
        background: "transparent",
      });
    } else {
      console.log("cursor brush false");
      $("*").css({
        cursor: "url(" + black_blur + "),auto",
      });
      $(".tbrush").css({
        background: "#383838",
      });
    }

    if (appCtx.brush === false) {
      appCtx.setClicked(false);
    }
    appCtx.setBrush(!appCtx.brush);
    console.log("setting brush", !appCtx.transposer);
  };

  const resetSelection = () => {
    $(".truler").css({
      background: "transparent",
    });
    $(".tmagic").css({
      background: "transparent",
    });
    $(".ttranspose").css({
      background: "transparent",
    });
    $(".tbrush").css({
      background: "transparent",
    });
    $(".ttracer").css({
      background: "transparent",
    });
    $(".teraserLine").css({
      background: "transparent",
    });
    //appCtx.ctx2.clearRect(0, 0, 900, 900);
    if (appCtx.ctx4)
      appCtx.ctx4.clearRect(
        0,
        0,
        appCtx.parent.current.width,
        appCtx.parent.current.height
      );
    appCtx.setLine(false);
    appCtx.setRuler(false);
    appCtx.setTransposer(false);
    appCtx.setBrush(false);
    appCtx.setTracer(false);
    appCtx.setEraserLine(false);
    appCtx.setDistanceMesured(0);
  };

  useEffect(() => {
    if (appCtx.isEchelle) {
      resetSelection();
      $("*").css({
        cursor: "url(" + pencil2 + "),auto",
      });
      appCtx.setZIndex1(4);
      appCtx.setZIndex2(3);
      appCtx.setZIndex4(2);
      appCtx.setZIndex5(5);
    } else {
      $("*").css({
        cursor: "default",
      });
      appCtx.setZIndex5(-1);
      appCtx.setZIndex1(4);
      appCtx.setZIndex2(3);
      appCtx.setZIndex4(2);
      if (appCtx.ctx4)
        appCtx.ctx4.clearRect(
          0,
          0,
          appCtx.parent.current.width,
          appCtx.parent.current.height
        );
    }
  }, [appCtx.isEchelle]);

  useEffect(() => {
    console.log("line changed REALLY ", appCtx.line);
  }, [appCtx.line]);
  const handleDragStart = (event) => {
    event.stopPropagation();
  };
  return (
    <Draggable onDragStart={handleDragStart}>
      <div id="toolbar">
        <div className="tbtop">
          <img
            onClick={handleCrossClick}
            className="nicross"
            src={cross}
            alt=""
          />
        </div>
        <div className="tbbody">
          <ul>
            <li>
              <label
                id="tmagic"
                onClick={() => tlineClicked()}
                className="tmagic"
              >
                <img src={magic} className="invert opacity" alt="" />
              </label>
            </li>
            <li>
              <label
                id="tbrush"
                onClick={() => tbrushClicked()}
                className="tbrush"
              >
                <img src={eraser} alt="" />
              </label>
            </li>
            <li>
              <label
                id="truler"
                onClick={() => trulerClicked()}
                className="truler"
              >
                <img src={ruler} className="invert" alt="" />
              </label>
            </li>
            <li>
              <label
                id="ttranspose"
                onClick={() => ttransposeClicked()}
                className="ttranspose"
              >
                <img src={eye} alt="" />
              </label>
            </li>

            <li>
              <label
                id="ttracer"
                onClick={() => ttraceClicked()}
                className="ttracer"
              >
                <img src={edit} className="" alt="" />
              </label>
            </li>
            <li>
              <label
                id="teraserLine"
                onClick={() => teraserLineClicked()}
                className="teraserLine"
              >
                <img src={cross} className="" alt="" />
              </label>
            </li>
            <li className="color-picker-parent">
              <div
                onClick={() => setShowColorPicker(!showColorPicker)}
                className="tgradient"
              >
                <div
                  className="color-picker-box"
                  style={{ backgroundColor: appCtx.activeColor }}
                ></div>
              </div>
              {showColorPicker && (
                <div className="color-picker" draggable={false}>
                  <SketchPicker
                    color={appCtx.activeColor}
                    onChangeComplete={(color) => {
                      appCtx.setActiveColor(color.hex);
                    }}
                  />
                </div>
              )}
            </li>
            <li className="color-picker-parent">
              <div
                onClick={() => appCtx.setIsEchelle(!appCtx.isEchelle)}
                className="tgradient"
                style={{
                  backgroundColor: appCtx.isEchelle ? "#232323" : "transparent",
                }}
              >
                <div className="echelle-box">E</div>
              </div>
            </li>

            {false && (
              <div>
                <li>
                  <a className="tselection">
                    <img src={selection} alt="" />
                  </a>
                </li>

                <li>
                  <a className="tcrop" href="#">
                    <img src={crop} alt="" />
                  </a>
                </li>
                <li>
                  <a className="teye" href="#">
                    <img src={eye} alt="" />
                  </a>
                </li>

                <li>
                  <a className="tstamp" href="#">
                    <img src={stamp} alt="" />
                  </a>
                </li>
                <li>
                  <a className="thistory" href="#">
                    <img src={history} alt="" />
                  </a>
                </li>
                <li>
                  <a className="teraser" href="#">
                    <img src={eraser} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tgradient" href="#">
                    <img src={gradient} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tblur" href="#">
                    <img src={blur} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tdodge" href="#">
                    <img src={dodge} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tpen" href="#">
                    <img src={pen} alt="" />
                  </a>
                </li>
                <li>
                  <a className="ttext" href="#">
                    <img src={text} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tpath" href="#">
                    <img src={path} alt="" />
                  </a>
                </li>
                <li>
                  <a className="trectangle" href="#">
                    <img src={rectangle} alt="" />
                  </a>
                </li>
                <li>
                  <a className="thand" href="#">
                    <img src={hand} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tmag" href="#">
                    <img src={mag} alt="" />
                  </a>
                </li>
                <li>
                  <a className="tgradient" href="#">
                    <img src={edit} alt="" />
                  </a>
                </li>
              </div>
            )}
          </ul>
        </div>
      </div>
    </Draggable>
  );
}
